import store from '../store';

export default ({ payload }) => {  
  store.dispatch({
    type: 'USERS.SET_USER',
    value: {
      id: payload.user.id,
      info: payload.user.info,
      token: payload.user.idToken, // TODO: Change to accessToken
      defaultProjectId: localStorage.getItem(`defaultProjectId`) // defaultProjectId // TODO: Change to "users.[userId].defaultProject"
    }
  });

  store.dispatch({
    type: 'USERS.SET_CURRENT_USER',
    value: payload.user.id
  });

  store.dispatch({
    type: 'USERS.SET_USER_RESPONSE',
    value: {
      id: payload.user.id,
      fetched: true,
      error: null
    }
  });

  /*
  analytics.set({
    userId: this.props.session.user.id
  });
  */
}
