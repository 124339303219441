import { CognitoIdentityServiceProvider } from 'aws-sdk';

const cognitoParams = {
  region: 'us-east-1'
};

const cognito = new CognitoIdentityServiceProvider(cognitoParams);

/**
 * Cognito refresh token
 * @param {Object} params
 * @param {String} params.clientId
 * @param {String} params.refreshToken
 * @returns {Promise}
 */
export const exchangeRefreshToken = async function (params) {
  const { refreshToken } = params;

  const authParams = {
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken
    }
  };

  var response = await cognito.initiateAuth(authParams).promise();
  return response;
}

/**
 * Cognito get user
 * @param {String} accessToken
 */
export const getUser = async function (accessToken) {
  var response = await cognito.getUser({ AccessToken: accessToken }).promise();
  return response;
}

export default { exchangeRefreshToken, getUser }
