import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";

// Pages
const HomePage = React.lazy(() => import("./pages/Home"));
const OpenPage = React.lazy(() => import("./pages/OpenProject"));
const MapPage = React.lazy(() => import("./pages/Map"));
const CallbackPage = React.lazy(() => import("./pages/Callback"));

const LoadingPage = (props) => {
  return <span>Carregando...</span>;
};

const Routes = (props) => {
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Router>
        <Route path="/" exact component={HomePage} />
        <Route path="/open" exact component={OpenPage} />
        <Route path="/callback" exact component={CallbackPage} />
        <Route path="/project/:projectId" component={MapPage} />
      </Router>
    </React.Suspense>
  );
};

export default Routes;
