//import { Hub } from 'aws-amplify';
//import session from '../shared/session';
//import { exchangeRefreshToken, getUser } from '../helpers/cognito';

export default () => {
  const refreshToken = localStorage.getItem('refreshToken');
  console.log('refresh token changed', refreshToken);

  //Hub.dispatch('access_token_ok', {});
  //Hub.dispatch('refresh_token_ok', {});
  //Hub.dispatch('refresh_token_err', {});
}
