var initialState = {
  zoom: null,
  center: {
    latitude: 0,
    longitude: 0
  },
  bounds: {
    south: null,
    west: null,
    north: null,
    east: null
  }
};

export default function (state = initialState, action) {
  if (action.type === 'MAP.SET_ZOOM') {
    return {
      ...state,
      zoom: action.value
    }
  }

  if (action.type === 'MAP.SET_CENTER') {
    return {
      ...state,
      center: action.value
    }
  }

  if (action.type === 'MAP.SET_BOUNDS') {
    var bounds = { ...state.bounds };
    
    if (action.value.south) {
      bounds.south = action.value.south;
    }

    if (action.value.west) {
      bounds.west = action.value.west;
    }

    if (action.value.north) {
      bounds.north = action.value.north;
    }

    if (action.value.east) {
      bounds.east = action.value.east;
    }

    return {
      ...state,
      bounds: bounds
    }
  }

  return state;
}