import store from '../store';

export default ({ payload }) => {
  store.dispatch({
    type: 'AUTH.SET_AUTHENTICATION_STATE',
    payload: {
      ...payload,
      error: null
    }
  });
}
