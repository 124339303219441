import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';
import session from '../../../shared/session';

/**
 * Load projects from API
 * @param {String} currentUserId
 */
async function loadProjectsApi (currentUserId) {
  const requestParams = {
    url: `${process.env.REACT_APP_API_ENDPOINT}/graphql/user`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${session.getToken()}`
    },
    json: true,
    data: {
      query: `
        query getUserProjects {
          getProjects {
            error {
              type
            }
            data {
              count
              edges {
                role
                permissions
                project {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {}
    }
  };

  const response = await axios(requestParams);
  const { getProjects } = response.data.data;

  if (getProjects.error) {
    throw new Error(getProjects.error.type);
  }

  return getProjects.data.edges.map((item) => {
    return {
      id: item.project.id,
      name: item.project.name,
      role: item.role,
      permissions: item.permissions
    }
  });
}

/**
 * Async load projects
 * @param {*} action
 */
function* asyncLoadProjects (action) {
  var stateUsers = yield select(state => state.users);
  var { currentUserId } = stateUsers;

  yield put({
    type: 'USERS.SET_PROJECTS_RESPONSE',
    value: {
      user: currentUserId,
      response: {
        fetched: false,
        error: null
      }
    }
  });

  try {
    var response = yield call(loadProjectsApi, currentUserId);

    yield put({
      type: 'USERS.SET_PROJECTS',
      value: {
        user: currentUserId,
        projects: Object.fromEntries(response.map((project) => {
          return [project.id, project];
        }))
      }
    });

    yield put({
      type: 'USERS.SET_PROJECTS_RESPONSE',
      value: {
        user: currentUserId,
        response: {
          fetched: true,
          error: null
        }
      }
    });
  } catch (e) {
    yield put({
      type: 'USERS.SET_PROJECTS_RESPONSE',
      value: {
        user: currentUserId,
        response: {
          fetched: true,
          error: e.message
        }
      }
    });
  }
}

export default asyncLoadProjects;
