
function MemoryStorage () {

  var inMemoryStorage = {};

  /**
   * Save value in memory
   * @param {String} key 
   * @param {any} value
   * @returns {MemoryStorage}
   */
  this.set = (key, value) => {
    inMemoryStorage[key] = value;
    return this;
  };

  /**
   * Check if value exists
   * @param {String} key
   * @returns {Boolean}
   */
  this.has = (key) => {
    return Object.keys(inMemoryStorage).indexOf(key) !== -1;
  };

  /**
   * Remove item from memory
   * @param {String} key
   * @returns {MemoryStorage}
   */
  this.remove = (key) => {
    delete(inMemoryStorage[key]);
    return this;
  }

  /**
   * Return storage value
   * @param {String} key
   * @returns {any}
   */
  this.get = function (key) {
    if (!this.has(key)) {
      return null;
    }

    return inMemoryStorage[key];
  }

  /**
   * Return all keys
   * @returns {Array<String>}
   */
  this.getKeys = function () {
    return Object.keys(inMemoryStorage);
  }
}

export default MemoryStorage;