var initialState = {
  data: {},
  currentUserId: null
};

const normalize = (user) => {
  return {
    id: null,
    fetched: false,
    error: null,
    info: {},
    projects: {
      fetched: false,
      error: null,
      data: []
    },
    currentProjectId: null,
    defaultProjectId: null,
    token: null,
    ...user
  }
};

const getUser = (id, state) => {
  if (state.data[id]) {
    return state.data[id];
  }

  return {};
}

const setUser = (id, user, state) => {
  var oldUser = getUser(id, state);
  var newUser = { ...oldUser, ...user };
  var newState = { ...state };
  newState.data[id] = newUser;
  return newState;
}

var actions = {
  'USERS.SET_USER': function (state, action) {
    const { value } = action;
    const { id } = value;
    var newState = { ...state };
    newState.data[id] = normalize(value);

    return newState;
  },
  'USERS.SET_USER_RESPONSE': function (state, action) {
    const { value } = action;
    const { id, fetched, error } = value;

    var newState = { ...state };
    newState.data[id].fetched = fetched;
    newState.data[id].error = error;

    return newState;
  },
  'USERS.SET_CURRENT_USER': function (state, action) {
    const { value } = action;

    return {
      ...state,
      currentUserId: value
    };
  },
  'USERS.SET_CURRENT_PROJECT': function (state, action) {
    const { value } = action;

    if (!state.currentUserId) {
      return state;
    }

    var newState = { ...state };

    if (!newState.data[state.currentUserId]) {
      newState.data[state.currentUserId] = {};
    }

    newState.data[state.currentUserId].currentProjectId = value;
    return newState;
  },
  'USERS.SET_DEFAULT_PROJECT': function (state, action) {
    var user = getUser(state.currentUserId, state);
    return setUser(state.currentUserId, { ...user, defaultProjectId: action.value }, state);
  },
  'USERS.SET_PROJECTS': function (state, action) {
    const { user, projects } = action.value;

    var oldUser = getUser(user, state);
    var newUser = { ...oldUser };

    newUser.projects.data = projects;
    return setUser(user, newUser, { ...state });
  },
  'USERS.SET_PROJECTS_RESPONSE': function (state, action) {
    const { user, response } = action.value;
    const { fetched, error } = response;

    var oldUser = getUser(user, state);
    var newUser = { ...oldUser };

    if (!newUser.projects) {
      newUser.projects = {
        fetched: false,
        error: null,
        data: {}
      };
    }

    newUser.projects.fetched = fetched;
    newUser.projects.error = error || null;

    return setUser(user, newUser, { ...state });
  }
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
