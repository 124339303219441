import { combineReducers } from 'redux';

import markersReducer from './markers';
import searchReducer from './search';
//import statusPreferencesReducer from './status-preferences';
//import filtersPreferencesReducer from './filters-preferences';
import sessionReducer from './session';
import mapReducer from './map';
import usersReducer from './users';
import mapPageReducer from './mapPage';
import authReducer from './auth';

/*
{
  ui: {
    searching: false,
    sidebarOpen: true,
    quickFilters: []
  },
  filters: [],
  scheme: [],
  markers: [],
  selectedMarkers: []
}
*/

export default combineReducers({
  auth: authReducer,
  users: usersReducer,
  mapPage: mapPageReducer,


  session: sessionReducer,
  map: mapReducer,
  markers: markersReducer,
  search: searchReducer,
  //statusPreferences: statusPreferencesReducer,
  //filtersPreferences: filtersPreferencesReducer
})
