import getCurrentUser from './getCurrentUser';

/**
 * Get current signed project from state
 * @param {Object} state
 * @returns {null|Object} selected project
 */
function getCurrentProject (state) {
  var user = getCurrentUser(state);
  var project = null;

  if (user) {
    if (user.currentProjectId) {
      const { currentProjectId, projects } = user;

      if (projects.data[currentProjectId]) {
        project = projects.data[currentProjectId];
      }
    }
  }

  return project;
}

export default getCurrentProject;
