import ReactGA from '../../analytics';

var initialState = {
  isFetching: false,
  loading: false,
  error: false,
  data: [],
  selecteds: []
};

export default function (state = initialState, action) {
  if (action.type === 'MARKERS.SET_SELECTEDS') {
    return {
      ...state,
      selecteds: action.value
    }
  }

  if (action.type === 'MARKERS.SET_LOADED') {
    return {
      ...state,
      isFetching: !Boolean(action.value),
      loading: !Boolean(action.value)
    }
  }

  if (action.type === 'MARKERS.SET_IS_FETCHING') {
    return {
      ...state,
      isFetching: Boolean(action.value),
      loading: !Boolean(action.value)
    }
  }

  if (action.type === 'MARKERS.SET_MARKERS') {
    return {
      ...state,
      data: action.value
    }
  }


  if (action.type === 'MARKERS.REQUEST') {
    return {
      ...state,
      isFetching: true,
      loading: true,
      error: false
    }
  }

  if (action.type === 'MARKERS.FETCH_SUCCESS') {
    ReactGA.event({
      category: 'Fetch',
      action: 'Fetch markers (success)'
    });

    return {
      ...state,
      isFetching: false,
      data: action.payload.data,
      loading: false,
      error: false
    }
  }

  if (action.type === 'MARKERS.FETCH_ERROR') {
    ReactGA.event({
      category: 'Fetch',
      action: 'Fetch markers (error)'
    });

    return {
      ...state,
      isFetching: false,
      data: [],
      loading: false,
      error: true
    }
  }

  return state;
}