const initialState = {
  q: null,
  id: null,
  trackId: null,
  title: null,
  status: [],
  properties: {},
  time: null,
  orderBy: null,
};

const actions = {
  'SEARCH.RESET': function () {
    return { ...initialState };
  },
  'SEARCH.CLEAR': function () {
    return { ...initialState };
  },
  'SEARCH.SET_ID': function (state, action) {
    return {
      ...state,
      id: action.value
    }
  },
  'SEARCH.SET_TIME': function (state, action) {
    return {
      ...state,
      time: action.value
    }
  },
  'SEARCH.SET_KEYWORDS': function (state, action) {
    return {
      ...state,
      q: action.value
    }
  },
  'SEARCH.SET_STATUS': function (state, action) {
    return {
      ...state,
      status: action.value
    }
  },
  'SEARCH.SET_FILTERS': function (state, action) {
    return {
      ...state,
      properties: action.value
    }
  },
  'SEARCH.SET_ORDER_BY': function (state, action) {
    return {
      ...state,
      orderBy: action.value
    }
  },
  'SEARCH.UPDATE': function (state, action) {
    var { payload } = action;
    var newState = { ...state };

    if (payload.q !== undefined) {
      newState.q = payload.q;
    }

    if (payload.title) {
      newState.title = payload.title;
    }

    if (payload.id) {
      newState.id = payload.id;
    }

    if (payload.trackId) {
      newState.trackId = payload.trackId;
    }

    if (payload.status) {
      newState.status = payload.status;
    }

    if (payload.properties) {
      newState.properties = payload.properties;
    }

    if (payload.orderBy) {
      newState.orderBy = payload.orderBy;
    }

    return newState;
  }
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
