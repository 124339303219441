//import store from '../store';
import session from '../shared/session';

export default ({ payload }) => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessToken');

  session.reset();
  session.emit();

  window.location.href = `${process.env.REACT_APP_AUTH_URL}/#/sign-out`;

  /*
  analytics.event({
    category: 'Click',
    action: 'Sign out'
  });
  */
}
