import { put } from 'redux-saga/effects';

function* sessionSaga (action) {
  let shouldDispatch = false;
  let eventPayload = null;

  if (action.type === 'SESSION' && action?.payload?.event === 'start') {
    eventPayload = {
      event: 'session_start',
      metadata: {
        userId: action.payload.userId
      }
    };
    shouldDispatch = true;
  }

  if (action.type === 'SESSION' && action?.payload?.event === 'error') {
    eventPayload = {
      ...action.payload,
      event: 'session_error'
    };
    shouldDispatch = true;
  }

  if (shouldDispatch) {
    yield put({
      type: 'EVENT',
      payload: eventPayload
    });
  }
}

export default sessionSaga;