import axios from "axios";

const LOGGING_SERVICE_ENDPOINT =
  process.env.LOGGING_SERVICE_ENDPOINT ||
  "https://hs898g1ssh.execute-api.us-east-1.amazonaws.com/prod"; //'http://54.164.152.102:8100';
const LOGGING_SERVICE_API_KEY =
  process.env.LOGGING_SERVICE_API_KEY || "TOKEN_DE_TESTE_SERVER";

/* const createAgent = () => {
  const clientOptions = {
    url: LOGGING_SERVICE_ENDPOINT,
    headers: {
      'Authorization': `apikey ${LOGGING_SERVICE_API_KEY}`
    }
  };

  return axios.create(clientOptions);
}; */

/**
 * Log event
 * @param {String} type
 * @param {Object} data
 * @param {Object} eventOptions
 */
export const sendLogEvent = (type, data, eventOptions = {}) => {
  const requestOptions = {
    method: "POST", // 'GET',
    url: `${LOGGING_SERVICE_ENDPOINT}/logs`, //`${LOGGING_SERVICE_ENDPOINT}/about`,
    headers: {
      "X-Event-Type": type || "log",
      Authorization: `apikey ${LOGGING_SERVICE_API_KEY}`,
    },
    data: {
      event: type,
      ...data,
    },
  };

  if (eventOptions && eventOptions.eventId) {
    requestOptions.headers["X-Event-Id"] = eventOptions.eventId;
  }

  console.log("Sending event request...", requestOptions);

  axios
    .request(requestOptions)
    .then((response) => {
      console.log("Event logged", response, { type, data });
    })
    .catch((err) => {
      console.log("process.env.LOGGING_SERVICE_API_KEY");
      console.log(err);
    });
};

export default {
  sendLogEvent,
};
