import { put, call } from 'redux-saga/effects';
import bugsnag from '../../../bugsnag';
import makeClient from '../../../shared/make-client';

/**
 * Fetch service images
 * @param {String} serviceId 
 */
const fetchServiceImages = async (serviceId) => {
  try {
    const apiClient = await makeClient();

    const body = {
      query: `
        query getServiceDetails ($input: FindServicesInput!) {
          getServices (input: $input) {
            error {
              type
            }
            data {
              count
              edges {
                id                
                images: getImages {
                  error {
                    type
                  }
                  data {
                    count
                    edges {
                      id
                      mime
                      getUrl
                    }
                  }
                }                
              }
            }
          }
        }
      `,
      variables: {
        input: {
          id: serviceId
        }
      }
    };

    const response = await apiClient.post('/graphql/query', body);
    const { getServices } = response.data.data;

    if (getServices.error) {
      throw new Error(getServices.error.type);
    }

    if (getServices.data.count < 1) {
      return new Error('not_found');
    }

    return getServices.data.edges[0].images;
  } catch (e) {
    throw e;
  }
}

function* asyncFetchServiceImages (action) {
  const { id } = action.payload;
  console.log('Fetching service images', action);

  try {
    var response = yield call(fetchServiceImages, id);

    yield put({
      type: 'MAP_PAGE.FETCH_SERVICE_IMAGES_DONE',
      payload: {
        id: id,
        result: {
          error: response.error,
          fetched: true,
          data: response.data
        }
      }
    });

  } catch (e) {
    console.warn(e);
    bugsnag.notify(e);

    yield put({
      type: 'MAP_PAGE.FETCH_SERVICE_IMAGES_DONE',
      payload: {
        id: id,
        result: {
          error: e,
          fetched: false,
          data: null
        }
      }
    });
  }
}

export default asyncFetchServiceImages;
