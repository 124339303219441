import { Hub } from "aws-amplify";

class Session {

  constructor () {

    // Credentials
    this.idToken = null;
    this.accessToken = null;
    this.refreshToken = null;

    this._reset();
    this._emit();
  }

  /**
   * Emit changes
   */
  _emit = () => {
    var newState = {
      touched: this.touched,
      fetching: this.fetching,
      authenticated: this.authenticated,
      user: this.user
    };

    Hub.dispatch('session_change', newState);
  }

  /**
   * Reset properties
   */
  _reset = () => {
    this.touched = false;
    this.fetching = false;
    this.authenticated = false;
    this.user = null;
  }

  reset = () => {
    this._reset();
    this._emit();
  }

  emit = () => {
    this._emit();
  }

  fromStorage = async () => {
    // Reload
    // Code here
  }

  setCredentials = (credentials) => {
    this.accessToken = credentials.accessToken;
    this.idToken = credentials.idToken;
    Hub.dispatch('session_tokens_change', { idToken: credentials.idToken, accessToken: credentials.accessToken });
  }

  subscribe = (observer) => {
    Hub.listen('session_tokens_change', observer);
  }

  invalidateAccessToken = () => {
    this.accessToken = null;
    Hub.dispatch('session_token_invalid');
  }

  getToken = () => {
    return this.idToken; // TODO: Replace for accessToken
  }

  getAccessToken = () => {
    if (!this.accessToken) {
      return null;
    }

    return this.accessToken;
  }

  start = () => {
    Hub.dispatch('request_authentication', {});
  }

  refreshSession = () => {
    Hub.dispatch('request_refresh_session', {});
  }

  /**
   * Start only once
   */
  startOnce = () => {
    if (!this.touched) {
      this.start();
    }
  }
}

export default new Session();
