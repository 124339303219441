import moment from 'moment';
import { call, select } from 'redux-saga/effects';

// Send logs
import { sendLogEvent } from '../../utils/logs.js';

const DISABLE_LOGS = process.env.REACT_APP_DISABLE_LOGS === 'true';

const getUserInfoSelector = (state) => {
  return {
    userId: state?.auth?.user?.id || undefined,
    userName: state?.auth?.user?.info?.name || undefined
  }
};

const getProjectInfoSelector = (state) => {
  var projectId;

  if (state.users.currentUserId) {
    projectId = state?.users?.data[state.users.currentUserId]?.currentProjectId || undefined;
  }

  return {
    projectId: projectId
  }
};

// markers.data[0].id
    // markers.data[0].title

const getServiceInfoSelector = (serviceId) => {
  return (state) => {
    if (!serviceId) {
      return null;
    }

    if (!state.markers || !state.markers.data || !Array.isArray(state.markers.data)) {
      return null;
    }

    var found = null;

    for(var i = 0; i < state.markers.data.length; i++) {
      if (state.markers.data[i].id === serviceId) {
        found = state.markers.data[i];
        break;
      }
    }

    return found;
  }
};

/**
 * Send event to logging-service
 * @param {Object} event 
 */
const sendEventLoggingService = function (event) {
  sendLogEvent(event.type, { content: event.content, metadata: event.metadata });
  return 'ok';
};

function* eventSaga (action) {
  if (action.type === 'EVENT') {
    //var isPrevented = false;
    var serviceId = action?.payload?.content?.serviceId || action?.payload?.metadata?.serviceId || null;
    var userInfo = yield select(getUserInfoSelector);
    var projectInfo = yield select(getProjectInfoSelector);
    var serviceInfo = yield select(getServiceInfoSelector(serviceId));
    var now = moment.utc().toISOString();

    var enhancedEvent = {
      type: action.payload.event || 'log',
      time: now,
      metadata: {
        ...userInfo,
        ...projectInfo,
        ...action.payload.metadata,
        serviceId: serviceId || undefined,
        serviceName: serviceInfo ? serviceInfo.title : undefined
      },
      content: {
        ...action.payload.content
      }
    };

    /*
    // Conditional dispatching
    if (action.conditional && action.conditional.onlySigned) {
      if (!userInfo) {
        isPrevented = true;
      }
    }
    */

    if (!DISABLE_LOGS) {
      yield call(sendEventLoggingService, enhancedEvent);
    }
  }
}

export default eventSaga;